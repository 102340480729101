"use client";
import fetchJson from "@/lib/fetchJson";
import { useState } from "react";
import { KeyedMutator } from "swr";
import { Button, Flex, IconButton, Table, Text, TextField } from "@radix-ui/themes";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomUiComponents/Dialog"

interface Props {
  post: Post;
  mutate: KeyedMutator<Campaign>;
}

export default function NewEditPost({ mutate, post }: Props) {
  const [data, setData] = useState({
    tags: post.tags,
    username: post.username,
    likes: post.likes,
    comments: post.comments,
    shares: post.shares,
    views: post.views,
  });
  const updateField = (name: string, value: any) => {
    if (["likes", "shares", "comments", "views"].includes(name)) {
      console.log(parseInt(value), value);
      if (!isNaN(parseInt(value))) {
        setData({
          ...data,
          [name]: parseInt(value),
        });
      }
    } else if (name === "username") {
      setData({
        ...data,
        username: value.replace("@", ""),
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const update = async () => {
    await fetchJson(`/post/${post.id}/`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    });
    mutate();
  };

  return (
    <Dialog.Root>
    <Dialog.Trigger>
      <IconButton variant="outline">
        <IconEdit className="icon-accent"/>
      </IconButton>
    </Dialog.Trigger>
    <Dialog.Portal>
    <Dialog.Content style={{maxWidth: "450px"}}>
      <Dialog.Title>Edit Post</Dialog.Title>
  
      <Flex direction="column" gap="3">
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Author
          </Text>
          <TextField.Root
            defaultValue={data.username}
            value={data.username}
            onChange={(e) => updateField("username", e.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
            Likes
          </Text>
          <TextField.Root
            defaultValue={data.likes}
            value={data.likes}
            onChange={(e) => updateField("likes", e.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
            Shares
          </Text>
          <TextField.Root
            defaultValue={data.shares}
            value={data.shares}
            onChange={(e) => updateField("shares", e.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
            Views
          </Text>
          <TextField.Root
            defaultValue={data.views}
            value={data.views}
            onChange={(e) => updateField("views", e.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
            Comments
          </Text>
          <TextField.Root
            defaultValue={data.comments}
            value={data.comments}
            onChange={(e) => updateField("comments", e.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
            Tags
          </Text>
          <Table.Root className="w-full text-sm text-left text-main-text mt-4">
            <Table.Body>
              {data.tags?.map((item: any, i: any) => (
                <Table.Row key={item.id}>
                  <Table.Cell className="px-6 py-2">
                    <TextField.Root
                      value={data.tags[i]}
                      onChange={(e) => {
                        const t = [...data.tags];
                        t[i] = e.target.value;
                        updateField("tags", t);
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <IconButton
                      variant="ghost"
                      onClick={() => {
                        const t = [...data.tags].filter((_, j) => j != i);
                        updateField("tags", t);
                      }}
                    >
                      <IconTrash className="icon" />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>))}
                  </Table.Body>
                  <IconButton
                    variant="ghost"
                    onClick={() => updateField("tags", [...data.tags, ""])}
                  >
                    <IconPlus className="icon" />
                  </IconButton>
                </Table.Root>
        </label>
      </Flex>
      <Flex gap="3" mt="4" justify="end">
        <Dialog.Close>
          <Button variant="soft" color="gray">
            Cancel
          </Button>
        </Dialog.Close>
        <Dialog.Close>
          <Button onClick={() => update()}>Save</Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
  );
}
